.eth-price {
  position: static;
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .eth-price {
    margin: 20px 0;
    width: auto;
    text-align: left;
  }
} 