.container {
  display: flex;
  gap: 40px;
  max-width: 1800px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

/* Добавляем стили для ссылок */
a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 5px;
    gap: 20px;
    width: 100%;
    max-width: 100%;
  }
} 

:root {
  --background-color: #ffffff;
  --text-color: #444;
  --heading-color: #333;
  --shadow-color: rgba(0,0,0,0.1);
}

[data-theme='dark'] {
  --background-color: #1a1a1a;
  --text-color: #e0e0e0;
  --heading-color: #ffffff;
  --shadow-color: rgba(255,255,255,0.1);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: all 0.3s ease;
} 