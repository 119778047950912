/* Стили для переключателя темы */
.theme-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

/* Стиль для темной темы */
[data-theme='dark'] .theme-toggle {
  background: #393939;
}

.theme-toggle:hover {
  opacity: 0.9;
} 