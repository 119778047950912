.projects-section {
  flex: 1;
  border-right: 1px solid #E5E5E5;
  padding-right: 40px;
}

.section-header {
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #E5E5E5;
  margin-right: -40px;
  margin-bottom: 30px;
}

.section-header h1 {
  cursor: pointer;
  margin: 0;
  padding: 10px;
  font-size: 48px;
  color: #333;
  transition: color 0.3s ease;
}

/* Добавляем стили для темной темы */
[data-theme='dark'] .section-header h1 {
  color: #fff;
}

/* Активное состояние - черная линия на сером border */
.section-header h1.active {
  border-bottom: 2px solid #000;
  margin-bottom: -1px;
}

/* Добавляем стили для темной темы */
[data-theme='dark'] .projects-section {
  border-right-color: #393939;
}

[data-theme='dark'] .section-header {
  border-bottom-color: #393939;
}

.project-card h3 {
  margin-top: 15px;
}

.project-card p {
  margin-top: 8px;
}

@media (max-width: 768px) {
  .projects-section {
    border-right: none;
    padding-right: 0;
    margin-top: -20px;
  }

  .section-header {
    margin-right: 0;
  }

  .section-header h1 {
    font-size: 32px;
  }

  .project-card h3 {
    margin-top: 15px;
    margin-left: 15px;
  }
  
  .project-card p {
    margin-top: 8px;
    margin-left: 15px;
  }
} 