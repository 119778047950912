.project-card {
  background: white;
  padding: 20px;
  padding-left: 50px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
  cursor: pointer;
}

.project-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.project-description {
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
}

.project-image {
  width: 100%;
  border-radius: 5px;
  margin-top: 15px;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}

.project-link {
  display: block;
  text-align: left;
  margin: 10px 0;
  margin-left: 0;
  padding-left: 0;
  color: #333;
  text-decoration: none;
  font-weight: 500;
}

.project-link:hover {
  text-decoration: none;
  color: #2802BF;
}

/* Добавляем стили для темной темы */
[data-theme='dark'] .project-card {
  background: white;  /* Возвращаем белый фон */
}

[data-theme='dark'] h3 {
  color: #565656;  /* Темно-серый для заголовков */
}

[data-theme='dark'] p {
  color: #565656;  /* Темно-серый для описания */
}

[data-theme='dark'] .project-link {
  color: #565656;  /* Темно-серый для ссылки */
}

[data-theme='dark'] .project-link:hover {
  color: #2802BF;  /* Оставляем синий при наведении */
}

/* Для карточки без ссылки (inprogress) оставляем обычный курсор */
.project-card.no-link {
  cursor: default;
}

/* Более строгие правила для мобильных устройств */
@media (max-width: 768px) {
  .project-card {
    padding: 15px;
    padding-left: 15px;  /* Уменьшаем левый отступ */
    margin-bottom: 20px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
  }

  .project-title {
    padding-left: 0;
    margin-left: 0;
    text-align: left;
  }

  .project-description {
    padding-left: 0;
    margin-left: 0;
    text-align: left;
  }

  .project-link {
    margin-left: 15px;  /* Добавляем отступ слева */
    padding-left: 0;
    text-align: left;
    display: inline-block;
    position: relative;
  }

  h3 {
    margin-left: 0;
    padding-left: 0;
  }
  
  .project-image {
    max-height: 300px;
  }
}

/* Добавляем стили для очень маленьких экранов */
@media (max-width: 480px) {
  .project-card {
    width: 100%;
    padding: 15px;
    padding-left: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  
  /* Дополнительно выравниваем все элементы */
  .project-title, 
  .project-description {
    padding-left: 0;
    margin-left: 0;
    text-align: left;
  }
  
  .project-link {
    padding-left: 0;
    margin-left: 15px;  /* Добавляем отступ слева */
    text-align: left;
  }
} 