.blog-post {
  max-width: 1800px;
  margin: 80px auto 0;
  padding: 0 20px 0 120px;
}

.blog-post h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: var(--heading-color);
}

.post-image {
  width: 45%;
  border-radius: 8px;
  margin: 20px 0 40px;
  box-shadow: 0 4px 12px var(--shadow-color);
  display: block;
}

.post-content {
  font-size: 1.1em;
  line-height: 1.8;
  color: var(--text-color);
  max-width: 1500px;
  margin: 0;
}

.post-content p {
  margin-bottom: 20px;
}

.post-content h2 {
  font-size: 1.8em;
  margin: 40px 0 20px;
  color: var(--heading-color);
}

@media (max-width: 768px) {
  .blog-post {
    margin-top: 60px;
    padding: 0 20px;
  }
  
  .post-image {
    width: 100%;
  }
} 