.blog-section {
  flex: 1;
  padding-right: 40px;
  margin-top: 80px;
}

.blog-section h1 {
  margin-bottom: 40px;
  padding: 0 20px;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .blog-section {
    padding-right: 0;
    margin-top: 80px;
  }

  .blog-section h1 {
    margin-bottom: 30px;
    padding: 0 20px;
  }

  .blog-posts {
    grid-template-columns: 1fr;
  }
}

.back-button {
  position: absolute;
  top: 40px;
  left: 40px;
  padding: 8px 20px;
  border: 2px solid #444;
  border-radius: 4px;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 9999;
  color: #444;
  display: block;
}

.back-button:hover {
  background: #444;
  color: #fff;
} 