.profile-section {
  width: 400px;
  padding-left: 40px;
}

.profile-image {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile-description {
  color: #666;
  line-height: 1.5;
}

/* Добавляем стили для ссылок в профиле */
.profile-section a {
  text-decoration: none;
  color: #666;
  transition: color 0.3s ease;
}

.profile-section a:hover {
  color: #000000;
}

@media (max-width: 768px) {
  .profile-section {
    width: 100%;
    order: -1;
    padding-left: 0;
    margin-bottom: 20px;
  }

  .profile-image {
    width: 90px;
    height: 90px;
  }
  
  /* Явно указываем стили для ссылок на мобильных устройствах */
  .profile-section a {
    text-decoration: none !important;
    color: #666;
  }
} 