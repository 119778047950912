* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Wix Madefor Display', Arial, sans-serif;
  padding: 40px;
  background-color: white;
}

@media (max-width: 768px) {
  body {
    padding: 20px;
  }
} 