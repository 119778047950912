.back-button {
  position: absolute;
  top: 40px;
  left: 40px;
  padding: 8px 20px;
  border: 2px solid #444;
  border-radius: 4px;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 9999;
  color: #444;
  display: block;
}

.back-button:hover {
  background: #444;
  color: #fff;
}

.project-page {
  padding: 40px;
  max-width: 1450px;
  margin: 0 auto;
}

.project-page h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.short-description {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.6;
  white-space: pre-line;
}

.project-status {
  display: block;
  margin-top: 8px;
  color: #666;
  font-size: 1em;
}

.project-image {
  width: 100%;
  max-width: 800px;
  margin: 25px 0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.full-description {
  color: #444;
  line-height: 1.8;
  font-size: 1.1em;
  white-space: pre-line;
  margin-bottom: 30px;
}

.full-description ul,
.full-description ol {
  margin: 10px 0;
  padding-left: 20px;
}

.full-description li {
  margin-bottom: 4px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 40px;
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.gallery-image {
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .project-page {
    padding: 20px;
    margin-top: 60px;
  }

  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .gallery {
    grid-template-columns: 1fr;
  }
} 