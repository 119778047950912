.language-switcher {
  position: absolute;
  top: 40px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.language-btn {
  padding: 5px 10px;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  font-family: 'Wix Madefor Display', Arial, sans-serif;
  font-size: 14px;
}

.language-btn.active {
  color: #333;
  font-weight: 600;
}

/* Добавляем только стиль для темной темы */
[data-theme='dark'] .language-btn.active {
  color: #fff;
}

.language-divider {
  color: #E5E5E5;
}

@media (max-width: 768px) {
  .language-switcher {
    position: absolute;
  }
} 