.blog-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0;
  width: 100%;
}

.blog-image {
  width: 100%;
  aspect-ratio: 10/7;
  object-fit: cover;
}

.blog-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

.blog-card h3 {
  margin: 15px;
  font-size: 24px;
  color: #333;
}

.blog-card p {
  margin: 15px;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .blog-card {
    width: 100%;
  }
} 